import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import AvenirNextCondensedMediumWoff from "./fonts/AvenirNextCondensed-Medium.woff";
import AvenirNextCondensedMediumWoff2 from "./fonts/AvenirNextCondensed-Medium.woff2";
import AvenirNextCondensedMediumTtf from "./fonts/AvenirNextCondensed-Medium.ttf";
import AvenirNextCondensedBoldWoff from "./fonts/AvenirNextCondensed-Bold.woff";
import AvenirNextCondensedBoldWoff2 from "./fonts/AvenirNextCondensed-Bold.woff2";
import AvenirNextCondensedBoldTtf from "./fonts/AvenirNextCondensed-Bold.ttf";

const font1 = {
  fontFamily: "AvenirNextCondensed",
  src: `url(${AvenirNextCondensedMediumWoff}) format('woff'), url(${AvenirNextCondensedMediumWoff2}) format('woff2'), url(${AvenirNextCondensedMediumTtf}) format('truetype')`,
  fontWeight: "normal",
  fontStyle: "normal",
  fontDisplay: "swap",
};

const font2 = {
  fontFamily: "AvenirNextCondensed",
  src: `url(${AvenirNextCondensedBoldWoff}) format('woff'), url(${AvenirNextCondensedBoldWoff2}) format('woff2'), url(${AvenirNextCondensedBoldTtf}) format('truetype')`,
  fontWeight: "bold",
  fontStyle: "normal",
  fontDisplay: "swap",
};

const light = {
  type: "light",
  background: {
    default: "#ff8f00",
  },
  text: {
    primary: "#1d1e1c",
  },
};

const theme = createMuiTheme({
  typography: {
    fontFamily: "AvenirNextCondensed, Roboto, Helvetica, Arial, sans-serif",
    fontSize: 15,
  },
  palette: light,
  mixins: {
    toolbar: {
      minHeight: 96,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [font1, font2],
      },
    },
  },
});

export default responsiveFontSizes(theme);
