import React, {
  createContext,
  useContext,
  useReducer,
  useRef,
  useEffect,
  useState,
  useCallback,
} from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import clsx from "clsx";
import {
  BrowserRouter,
  Switch,
  Route,
  NavLink,
  Redirect,
  useLocation,
} from "react-router-dom";
import {
  config,
  useTransition,
  useChain,
  useTrail,
  useSpring,
  animated,
} from "react-spring";
import { useTranslation, Trans } from "react-i18next";
import theme from "./theme";

// ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————— MAIN

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <DataContextProvider>
          <Layout>
            <Switch>
              <Route path="/home" exact>
                <Home />
              </Route>
              <Route path="/about" exact>
                <About />
              </Route>
              <Route path="/making" exact>
                <Making />
              </Route>
              <Route path="/model" exact>
                <Model />
              </Route>
              <Route path="/laying" exact>
                <Laying />
              </Route>
              <Route path="/images" exact>
                <Images />
              </Route>
              <Route path="/order" exact>
                <Order />
              </Route>
              <Redirect to="/home" exact />
            </Switch>
          </Layout>
        </DataContextProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

const Layout = ({ children }) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={classes.root}>
      <main className={classes.main}>{children}</main>
      <Menu />
      <Nav />
    </div>
  );
};

//  ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————— PAGES

const Home = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container} maxWidth="sm">
      <div className={classes.parent}>
        <Img
          img={{ description: "ecosignum", link: "/img/ecosignum.png" }}
          className={classes.img}
        />
      </div>
    </Container>
  );
};

const About = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container className={classes.container} maxWidth="sm">
      <Box py={2} display="flex" justifyContent="center">
        <Img
          img={{ description: "ecosignum", link: "/img/ecosignum-nail.png" }}
          className={classes.icon}
        />
      </Box>
      <Typography className={clsx(classes.text, classes.bold)} variant="h4">
        {t("menu.about")}
      </Typography>
      <Typography className={classes.text}>{t("about.text1")}</Typography>
      <Typography className={classes.text}>{t("about.text2")}</Typography>
      <Typography className={classes.text}>{t("about.text3")}</Typography>
    </Container>
  );
};

const Making = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container className={classes.container} maxWidth="sm">
      <Box py={2} display="flex" justifyContent="center">
        <Img
          img={{ description: "ecosignum", link: "/img/ecosignum-nail.png" }}
          className={classes.icon}
        />
      </Box>
      <Typography className={clsx(classes.text, classes.bold)} variant="h4">
        {t("menu.making")}
      </Typography>
      <Typography className={classes.text}>{t("making.text1")}</Typography>
      <Typography className={classes.text}>{t("making.text2")}</Typography>
      <Typography className={classes.text}>{t("making.text3")}</Typography>
    </Container>
  );
};

const Model = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container className={classes.container} maxWidth="sm">
      <Box py={2} display="flex" justifyContent="center">
        <Img
          img={{ description: "ecosignum", link: "/img/ecosignum-nail.png" }}
          className={classes.icon}
        />
      </Box>
      <Typography className={clsx(classes.text, classes.bold)} variant="h4">
        {t("menu.model")}
      </Typography>
      <Typography className={classes.text}>{t("model.text1")}</Typography>
      <Typography className={classes.text}>{t("model.text2")}</Typography>
      <Typography className={classes.text}>{t("model.text3")}</Typography>
      <Typography className={classes.text}>{t("model.text4")}</Typography>
    </Container>
  );
};

const Laying = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container className={classes.container} maxWidth="sm">
      <Box py={2} display="flex" justifyContent="center">
        <Img
          img={{ description: "ecosignum", link: "/img/ecosignum-nail.png" }}
          className={classes.icon}
        />
      </Box>
      <Typography className={clsx(classes.text, classes.bold)} variant="h4">
        {t("menu.laying")}
      </Typography>
      <Typography className={classes.text}>{t("laying.text1")}</Typography>
      <Typography className={classes.text}>{t("laying.text2")}</Typography>
      <div className={classes.parent}>
        <Img
          img={{ description: "ecosignum", link: "/img/ecosignum-laying.png" }}
          className={classes.img}
        />
      </div>
    </Container>
  );
};

const GALLERY = ["/img/ecosignum-1.png", "/img/ecosignum-2.png"];

const Images = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container className={classes.container} maxWidth="sm">
      <Box py={2} display="flex" justifyContent="center">
        <Img
          img={{ description: "ecosignum", link: "/img/ecosignum-nail.png" }}
          className={classes.icon}
        />
      </Box>
      <Typography className={clsx(classes.text, classes.bold)} variant="h4">
        {t("menu.images")}
      </Typography>
      <Slideshow images={GALLERY} />
    </Container>
  );
};

const Order = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container className={classes.container} maxWidth="sm">
      <Box py={2} display="flex" justifyContent="center">
        <Img
          img={{ description: "ecosignum", link: "/img/ecosignum-nail.png" }}
          className={classes.icon}
        />
      </Box>
      <Typography className={clsx(classes.text, classes.bold)} variant="h4">
        {t("order.text1")}
      </Typography>
      <Typography className={classes.text}>{t("order.text2")}</Typography>
      <Typography className={clsx(classes.text, classes.bold)} variant="h4">
        {t("order.text3")}
      </Typography>
      <Typography className={classes.info}>
        <Trans
          i18nKey="order.text4"
          components={{
            address: (
              <Uri
                className={classes.link}
                url={t("order.address")}
                target="_blank"
              />
            ),
          }}
        />
      </Typography>
      <Typography className={classes.info}>
        <Trans
          i18nKey="order.text5"
          components={{
            tel: (
              <Uri className={classes.link} url={`tel:${t("order.phone")}`} />
            ),
            email: (
              <Uri
                className={classes.link}
                url={`mailto:${t("order.email")}`}
              />
            ),
          }}
        />
      </Typography>
    </Container>
  );
};

//  ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————— COMPONENTS

const Img = ({ img, className }) => (
  <img className={className} alt={img.description} src={img.link} />
);

const Uri = ({ url, className, target = "_self", children }) => (
  <Link className={className} href={url} target={target}>
    {children}
  </Link>
);

const Slideshow = ({ images = [] }) => {
  const classes = useStyles();
  const [index, setIndex] = useState(0);

  const onClick = useCallback(
    () => images.length > 0 && setIndex((s) => (s + 1) % images.length),
    [images]
  );

  const transitions = useTransition(index, (p) => p, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    trail: 50,
  });

  if (!images || images.length === 0) return null;

  return (
    <div className={classes.slideshow}>
      <div className={classes.slide} onClick={onClick}>
        {transitions.map(({ item, key, props }) => (
          <animated.div key={key} style={props}>
            <img alt="ecosignum" src={images[item]} />
          </animated.div>
        ))}
        <Typography component="span" variant="h6">
          {`${index + 1} / ${images.length}`}
        </Typography>
      </div>
    </div>
  );
};

const Nav = () => {
  const classes = useStyles();

  return (
    <div className={classes.nav}>
      <div className={classes.left}></div>
      <div className={classes.right}>
        <Hamburger />
      </div>
    </div>
  );
};

const Menu = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { menu, setMenu } = useData();

  const items = [
    { label: t("menu.home"), route: "/home" },
    { label: t("menu.about"), route: "/about" },
    { label: t("menu.making"), route: "/making" },
    { label: t("menu.model"), route: "/model" },
    { label: t("menu.laying"), route: "/laying" },
    { label: t("menu.images"), route: "/images" },
    { label: t("menu.order"), route: "/order" },
  ];

  const springRef = useRef();
  const spring = useSpring({
    ref: springRef,
    transform: menu ? "translateY(0%)" : "translateY(-100%)",
  });

  const trailRef = useRef();
  const trail = useTrail(items.length, {
    ref: trailRef,
    opacity: menu ? 1 : 0,
    p: menu ? 0 : 30,
    from: { opacity: 0, p: 30 },
    config: config.stiff,
  });

  useChain(menu ? [springRef, trailRef] : [trailRef, springRef], [0, 0.4]);

  return (
    <animated.div className={classes.menu} style={spring}>
      <Box px={2} py={6} textAlign="center" component="nav">
        {trail.map(({ p, ...rest }, i) => (
          <animated.div
            key={i}
            className={classes.trail}
            style={{
              ...rest,
              transform: p.interpolate((p) => `translate3d(${p}%,0,0)`),
            }}
          >
            {!!items[i].route && (
              <Typography
                variant="h6"
                className={clsx(classes.link, classes.bold)}
                component={NavLink}
                to={items[i].route}
                activeClassName={classes.active}
                onClick={() => setMenu(false)}
              >
                {items[i].label}
              </Typography>
            )}
            {!!items[i].url && (
              <Link
                variant="h6"
                className={clsx(classes.link, classes.bold)}
                target="_blank"
                href={items[i].url}
              >
                {items[i].label}
              </Link>
            )}
          </animated.div>
        ))}
      </Box>
    </animated.div>
  );
};

const Hamburger = () => {
  const classes = useStyles();
  const { menu, setMenu } = useData();

  const trans = (p, r) => `translateY(${p}px) rotateZ(${r}deg)`;
  const p1 = menu ? 0 : -24;
  const r1 = menu ? -45 : 0;
  const p2 = menu ? 0 : 24;
  const r2 = menu ? 45 : 0;

  const prop1 = useSpring({ opacity: menu ? 0 : 1 });
  const prop2 = useSpring({ transform: trans(p1, r1) });
  const prop3 = useSpring({ transform: trans(p2, r2) });

  return (
    <div className={classes.hamburger} onClick={setMenu}>
      <animated.div style={prop1} />
      <animated.div style={prop2} />
      <animated.div style={prop3} />
    </div>
  );
};

// ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————— STORE

const DataContext = createContext();
const useData = () => useContext(DataContext);
const DataContextProvider = ({ children }) => {
  const [menu, setMenu] = useReducer((i) => !i, false);

  return (
    <DataContext.Provider value={{ menu, setMenu }}>
      {children}
    </DataContext.Provider>
  );
};

// ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————— STYLES

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    minWidth: "320px",
    height: "100vh",
    overflow: "auto",
    animation: "$fadein ease 2s",
  },
  "@keyframes fadein": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  main: {
    paddingTop: theme.mixins.toolbar.minHeight,
    paddingBottom: theme.mixins.toolbar.minHeight,
  },
  nav: {
    position: "fixed",
    top: 0,
    width: "100%",
    height: theme.mixins.toolbar.minHeight,
    display: "flex",
    justifyContent: "center",
  },
  menu: {
    position: "fixed",
    top: 0,
    paddingTop: theme.mixins.toolbar.minHeight,
    paddingBottom: theme.mixins.toolbar.minHeight,
    width: "100%",
    height: "100vh",
    overflowX: "hidden",
    overflowY: "scroll",
    willChange: "transform",
    borderBottom: `3px solid ${theme.palette.text.primary}`,
    backgroundColor: theme.palette.background.default,
    "& > * > *": {
      padding: theme.spacing(3),
    },
    transform: "translateY(-100%)",
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      borderBottom: `3px solid ${theme.palette.text.primary}`,
    },
    whiteSpace: "nowrap",
  },
  active: {
    borderBottom: `3px solid ${theme.palette.text.primary}`,
  },
  left: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },
  right: {
    padding: theme.spacing(0, 2),
  },
  hamburger: {
    position: "relative",
    flexDirection: "row",
    width: 0.7 * theme.mixins.toolbar.minHeight,
    height: "100%",
    userSelect: "none",
    cursor: "pointer",
    "& > *": {
      position: "absolute",
      top: "50%",
      left: "0",
      width: "100%",
      marginTop: -2,
      height: 4,
      borderRadius: 2,
      backgroundColor: theme.palette.text.primary,
    },
  },
  trail: {
    willChange: "transform, opacity",
  },
  container: {
    padding: theme.spacing(4),
  },
  text: {
    textAlign: "center",
    paddingBottom: theme.spacing(6),
  },
  bold: {
    fontWeight: "bold",
  },
  img: {
    display: "block",
    width: "100%",
    userSelect: "none",
    padding: theme.spacing(4, 0),
  },
  icon: {
    display: "block",
    width: "48px",
    userSelect: "none",
  },
  parent: {
    position: "relative",
    [theme.breakpoints.up("xs")]: {
      padding: theme.spacing(0, 0),
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 8),
    },
  },
  info: {
    textAlign: "center",
  },
  slideshow: {
    position: "relative",
    width: "100%",
    border: `3px solid ${theme.palette.text.primary}`,
    borderRadius: 2,
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  slide: {
    position: "relative",
    width: "100%",
    paddingTop: "133%",
    overflow: "hidden",
    cursor: "pointer",
    "& > div": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      willChange: "opacity",
    },
    "& > div >  img": {
      display: "block",
      width: "100%",
      userSelect: "none",
    },
    "& > span": {
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      textAlign: "center",
      color: "white",
      padding: theme.spacing(),
      userSelect: "none",
    },
  },
  caption: {
    textAlign: "center",
  },
}));

export default App;
